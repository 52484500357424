import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { postSubtitle, postBody } from "./post.module.css";
import Layout from "../../components/layout";
import { PostBodyType, SitedataType } from "../../interfaces";

interface DataProps {
  post: PostBodyType,
  sitedata: SitedataType
}

const Post = ({ data }: PageProps<DataProps>) => {
 console.log(data)
  return (
    <Layout pageTitle={data.post.frontmatter.title}>
      <p className={postSubtitle}>Posted on {data.post.frontmatter.date} by {data.sitedata.siteMetadata.author}</p>
      <div className={postBody}>
        <MDXRenderer>{data.post.body}</MDXRenderer>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    post: mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
      body
    }
    sitedata: site {
      siteMetadata {
        author
      }
    }
  }
`;

export default Post;
